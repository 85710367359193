<!--
 * @Description: 外部的header
 * @Author: zhang zhen
 * @Date: 2024-09-23 09:46:30
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-09-27 23:35:33
 * @FilePath: /page-sass/src/components/tools/outSideHeader.vue
-->
<template>
  <div class="top-cover-header">
    <div class="top-header">
      <div class="left-box">
        <img src="/small-logo.png" alt="" class="logo" />
      </div>
      <div class="right-box">
        <router-link tag="div" to="/" class="link-text">返回首页</router-link>
        <!-- 登录之后 显示消息中心 -->
        <router-link tag="div" to="/messageManagement/messageList" class="nav-link">
          <a-popover
            placement="bottomRight"
            :autoAdjustOverflow="true"
            :arrowPointAtCenter="false"
            overlayClassName="header-notice-wrapper"
            :overlayStyle="{ width: '364px', top: '50px' }"
          >
            <template slot="content">
              <div class="header-title">
                <img src="/notice_icon.png" alt="" class="alarm" />
                <span>消息中心</span>
              </div>
              <div class="notice-content-item">
                <span class="left-label">
                  <span class="label">系统消息</span>
                  <a-badge
                    :count="countInfo.msgCategory_1 || 0"
                    :overflow-count="999"
                    :number-style="{ backgroundColor: '#EE4261' }"
                    v-if="countInfo.msgCategory_1"
                  />
                  <a-badge
                    v-else
                    :showZero="true"
                    count="0"
                    :number-style="{
                      backgroundColor: '#EFEFEF',
                      color: 'rgba(0, 0, 0, .25)',
                      boxShadow: '0 0 0 1px #d9d9d9 inset',
                    }"
                  />
                </span>
                <img src="/showInfo.png" alt="" class="showInfo" @click="handleJump(1)" />
              </div>
              <div class="notice-content-item">
                <span class="left-label">
                  <span class="label">服务消息</span>
                  <a-badge
                    :count="countInfo.msgCategory_2 || 0"
                    :overflow-count="999"
                    :number-style="{ backgroundColor: '#EE4261' }"
                    v-if="countInfo.msgCategory_2"
                  />
                  <a-badge
                    v-else
                    :showZero="true"
                    count="0"
                    :number-style="{
                      backgroundColor: '#EFEFEF',
                      color: 'rgba(0, 0, 0, .25)',
                      boxShadow: '0 0 0 1px #d9d9d9 inset',
                    }"
                  />
                </span>
                <img src="/showInfo.png" alt="" class="showInfo" @click="handleJump(2)" />
              </div>
              <div class="notice-content-item">
                <span class="left-label">
                  <span class="label">客户消息</span>
                  <a-badge
                    :count="countInfo.msgCategory_3 || 0"
                    :overflow-count="999"
                    :number-style="{ backgroundColor: '#EE4261' }"
                    v-if="countInfo.msgCategory_3"
                  />
                  <a-badge
                    v-else
                    :showZero="true"
                    count="0"
                    :number-style="{
                      backgroundColor: '#EFEFEF',
                      color: 'rgba(0, 0, 0, .25)',
                      boxShadow: '0 0 0 1px #d9d9d9 inset',
                    }"
                  />
                </span>
                <img src="/showInfo.png" alt="" class="showInfo" @click="handleJumpChat" />
              </div>
            </template>
            <a-badge :count="messageCount" :number-style="{ backgroundColor: '#EE4261' }" :overflow-count="999">
              <div class="link-text">消息</div>
            </a-badge>
          </a-popover>
        </router-link>
        <!-- <Location style="margin-right: 0;" /> -->
        <Menus :showEntry="false" class="noLeft" />
      </div>
    </div>
  </div>
</template>

<script>
import Notice from '@/mixins/notice'
import Menus from '@/components/tools/menus.vue'
import SendMethods from '@/mixins/sendMethods.js'
import { mapState, mapActions } from 'vuex';

export default {
  mixins: [SendMethods, Notice],
  components: {
    Menus,
  },
  data() {
    return {}
  },
  computed: {
        ...mapState({
            messageList: (state) => state.messageList, // 消息列表
            messageCount: (state) => state.messageCount, // 平台信息
        }),
    },
  methods: {
    ...mapActions(['deleteMessageList']),
  },
}
</script>

<style lang="less" scoped>
.top-cover-header {
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 45;
  .top-header {
    width: 1112px;
    height: 56px;
    margin: 0 auto;
    // margin-bottom: 21px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
      height: 30px;
      margin-right: 34px;
    }

    .left-box {
      display: flex;
      align-items: center;
    }

    .right-box {
      display: flex;
      align-items: center;
      grid-gap: 32px 32px;
      & > div {
        cursor: pointer;
      }
      .send-btn {
        background-color: #fff2ea;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ff6e2d;
        font-size: 12px;
        font-weight: 500;
        width: 80px;
        height: 30px;
        cursor: pointer;
      }

      .split-line {
        width: 1px;
        height: 20px;
        background: #efefef;
        margin: 0 21px;
      }

      .back {
        width: 16px;
        cursor: pointer;
        margin-right: 25px;
      }
    }
  }
}
</style>
