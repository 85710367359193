<template>
  <a-cascader :field-names="{ label: 'regionName', value: 'regionId', children: 'children' }" :options="regionOptions"
    :load-data="loadData" placeholder="所在地区" allowClear change-on-select style="width: 128px;" @change="handleChangeLocation">
    <a-icon type="caret-down" slot="suffixIcon" style="color: #8c8c8c" />
  </a-cascader>
</template>

<script>
import { getAction } from '@/api/manage'
import { mapState, mapGetters } from 'vuex'

export default {
  data() {
    return {
      regionOptions: [],
      location: {
        regionId: undefined,
        regionIdName: '',
        regionIdTwo: undefined,
        regionIdTwoName: ''
      },
      regionList: [],
      regionIdTwoList: [],
      selectedRegion: undefined,
      coverContainer: (node) => node.parentNode,
    }
  },
  computed: {
    ...mapState({
      hasLogin: state => state.user.isLogin,
      regionData: state => state.user.regionData
    })
  },
  created () {
    this.loadRegionList();
  },
  methods: {
    loadRegionList() {
      getAction('/area/queryByLevel', {
        level: 1
      }).then(res => {
        const { success, data } = res
        if (success) {
          this.regionOptions = data.map(item => ({ ...item, isLeaf: false }))
          if (this.selectedRegion && this.selectedRegion.length) {
            let regionIdRoot = this.selectedRegion[0]
            let targetItem = this.regionOptions.find(i => i.regionId == regionIdRoot)
            this.handleInitData(targetItem)
          }
        }
      })
    },
    handleInitData(targetOption) {
      console.log(targetOption)
      const { regionId, regionName, level } = targetOption
      targetOption.loading = true
      getAction('/area/queryByParentIdList', {
        parentId: regionId
      }).then(res => {
        const { success, data } = res
        if (success) {
          targetOption.loading = false
          targetOption.children = data.map(item => ({ ...item, isLeaf: true }))
          this.$forceUpdate()
        }
      })
    },
    loadData(selectedOptions) {
      const targetOption = selectedOptions[selectedOptions.length - 1]
      // load options lazily
      this.handleInitData(targetOption)
    },
    handleChangeLocation(value, selectedOptions) {
      console.log(selectedOptions)
      // let str = selectedOptions.map(i => i.regionId).toString();
      this.$emit('change', selectedOptions ? selectedOptions[selectedOptions.length - 1].regionId : null)
    },
    ...mapGetters(['isLogin']),
    handleChangeRootId() {
      this.regionIdTwoList = []
      this.location.regionIdTwo = undefined
      this.location.regionIdName = this.regionList.find(i => i.regionId == this.location.regionId)['regionName']
      this.handleLoadCity('regionIdTwo')
    },
    handleUpdateView() {
      this.$forceUpdate()
    },
    handleSaveCity() {
      this.location.regionIdTwoName = this.regionIdTwoList.find(i => i.regionId == this.location.regionIdTwo)[
        'regionName'
      ]
    },
    handleLoadCity(key, needClean = true) {
      let parentId = ''
      if (key == 'regionIdTwo') {
        this.regionIdTwoList = []
        needClean && (this.location.regionIdTwo = undefined)
        parentId = this.location.regionId
      }
      getAction('/area/queryByParentIdList', {
        parentId
      }).then(res => {
        const { success, data } = res
        if (success) {
          this[`${key}List`] = data
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.location {
  cursor: pointer;
  margin-right: 21px;
  display: flex;
  align-items: center;
}
::v-deep.ant-cascader-picker {
  color: #131212;
  background: transparent;
  font-weight: 400;
}
::v-deep.ant-input {
  color: #131212;
  padding: 4px 0!important;
}
::v-deep .ant-cascader-input.ant-input {
  padding: 0;
  padding-right: 15px;
  &::placeholder {
        color: #131212!important;
        /* 设置占位符文本的颜色 */
    }
}
::v-deep .ant-cascader-picker-label {
  padding: 0;
  padding-right: 15px;
  font-size: 16px;
}

::v-deep .ant-cascader-picker-arrow {
  right: 0;
}

.inSelect {
  ::v-deep.ant-cascader-picker {
        color: #FF6E2D!important;
    }
}
</style>