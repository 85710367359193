<template>
    <a-popconfirm placement="bottom" overlayClassName="coverSelector" :destroyTooltipOnHide="true"
        @visibleChange="handleChangeVisible">
        <div slot="icon"></div>
        <div class="picker topPicker" slot="title">
            <a-cascader :field-names="{ label: 'regionName', value: 'regionId', children: 'children' }"
                :options="regionOptions" :load-data="loadData" placeholder="请选择省市" allowClear change-on-select
                style="width: 188px;" :getPopupContainer="coverContainer" v-model="selectedRegion"
                @change="handleChangeLocation" />
        </div>
        <div class="location">
            <span>{{ regionStr }}</span>
            <img src="~@/assets/newHomePage/downNew.png" style="width: 16px;margin-left: 6px;cursor: pointer;" />
        </div>
    </a-popconfirm>
</template>

<script>
import { getAction } from '@/api/manage'
import { mapState, mapGetters } from 'vuex'

export default {
    data() {
        return {
            regionOptions: [],
            location: {
                regionId: undefined,
                regionIdName: '',
                regionIdTwo: undefined,
                regionIdTwoName: ''
            },
            pageName: 'homePage',
            regionList: [],
            regionIdTwoList: [],
            selectedRegion: ['310000000000'],
            regionStr: '上海市',
            coverContainer: () => document.querySelector('.right-box')
        }
    },
    computed: {
        ...mapState({
            hasLogin: state => state.user.isLogin,
            regionData: state => state.user.regionData
        })
    },
    methods: {
        loadRegionList() {
            getAction('/area/queryByLevel', {
                level: 1
            }).then(res => {
                const { success, data } = res
                if (success) {
                    this.regionOptions = data.map(item => ({ ...item, isLeaf: false }))
                    if (this.selectedRegion && this.selectedRegion.length) {
                        let regionIdRoot = this.selectedRegion[0]
                        let targetItem = this.regionOptions.find(i => i.regionId == regionIdRoot)
                        this.handleInitData(targetItem)
                    }
                }
            })
        },
        handleInitData(targetOption) {
            console.log(targetOption)
            const { regionId, regionName } = targetOption
            targetOption.loading = true
            getAction('/area/queryByParentIdList', {
                parentId: regionId
            }).then(res => {
                const { success, data } = res
                if (success) {
                    targetOption.loading = false
                    targetOption.children = data.map(item => ({ ...item, isLeaf: true }))
                    this.$forceUpdate()
                }
            })
        },
        loadData(selectedOptions) {
            const targetOption = selectedOptions[selectedOptions.length - 1]
            // load options lazily
            this.handleInitData(targetOption)
        },
        handleChangeLocation(value, selectedOptions) {
            console.log(selectedOptions)
            let str = selectedOptions.map(i => i.regionName)
            this.regionStr = str.join(' / ')
        },
        ...mapGetters(['isLogin']),
        // loadRegionList() {
        //   getAction('/area/queryByLevel', {
        //     level: 1
        //   }).then(res => {
        //     const { success, data } = res
        //     if (success) {
        //       this.regionList = data
        //     }
        //   })
        // },
        handleChangeRootId() {
            this.regionIdTwoList = []
            this.location.regionIdTwo = undefined
            this.location.regionIdName = this.regionList.find(i => i.regionId == this.location.regionId)['regionName']
            this.handleLoadCity('regionIdTwo')
        },
        handleUpdateView() {
            this.$forceUpdate()
        },
        handleSaveCity() {
            this.location.regionIdTwoName = this.regionIdTwoList.find(i => i.regionId == this.location.regionIdTwo)[
                'regionName'
            ]
        },
        handleLoadCity(key, needClean = true) {
            let parentId = ''
            if (key == 'regionIdTwo') {
                this.regionIdTwoList = []
                needClean && (this.location.regionIdTwo = undefined)
                parentId = this.location.regionId
            }
            getAction('/area/queryByParentIdList', {
                parentId
            }).then(res => {
                const { success, data } = res
                if (success) {
                    this[`${key}List`] = data
                }
            })
        },
        handleChangeVisible(e) {
            if (e) {
                // this.location = Object.assign({}, this.regionData)
                this.loadRegionList()
                // this.location.regionId && this.handleLoadCity('regionIdTwo', false)
            }
        },
    },
}
</script>

<style lang="less" scoped>
.location {
    cursor: pointer;
  margin-right: 21px;
  display: flex;
  align-items: center;
}
</style>